import { Job } from "context/jobContext";

export const opportunities: Job[] = [
    {
        title: "Backend Developer",
        department: ["Development", "R&D"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop, test, and maintain backend systems and databases to ensure strong functionality and optimization",
            "Collaborate with cross-functional teams to define, design, and ship new features",
            "Optimize system performance, scalability, and security of services to support data-heavy applications",
            "Ensure clean, maintainable code with consistent testing and documentation practices",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Proven experience in backend development with a focus on scalable systems",
            "Strong proficiency in programming languages such as Node.js, Java, or Python",
            "Knowledge of database systems, cloud services, and distributed systems architecture",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Familiarity with cybersecurity standards is an advantage"
        ]
    },
    {
        title: "Blockchain Developer",
        department: ["Development", "Blockchain"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Design, develop, and maintain blockchain-based solutions, focusing on data security and transaction transparency",
            "Implement, test, and audit smart contracts for integrity and performance",
            "Work with cross-functional teams to integrate blockchain solutions into existing services",
            "Stay informed on emerging blockchain technologies and propose innovative solutions",
        ],
        qualifications: [
            "Strong experience in blockchain development, with a deep understanding of consensus algorithms and blockchain security",
            "Proficiency in programming languages like Solidity or Rust, with experience in secure code practices",
            "Bachelor's degree in Computer Science or related field preferred",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Experience with zero-knowledge proofs or zero-trust ecosystems is beneficial"
        ]
    },
    {
        title: "Full-Stack Developer",
        department: ["Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and maintain both frontend and backend systems to support seamless user experiences",
            "Implement responsive designs and ensure cross-platform compatibility",
            "Collaborate closely with product, design, and tech teams to refine project requirements and achieve product goals",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience with full-stack development, particularly in frontend and backend integration",
            "Proficiency in languages such as JavaScript, Java, or Python, and familiarity with frameworks like React or Angular",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Prior experience in blockchain or cybersecurity projects is a plus"
        ]
    },
    {
        title: "DevSecOps Engineer",
        department: ["Development", "Security"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Integrate security best practices into all stages of the software development lifecycle",
            "Manage and monitor security tools and ensure compliance with security standards",
            "Collaborate with development, operations, and security teams to implement security protocols",
            "Identify and mitigate vulnerabilities, performing regular security assessments",
        ],
        qualifications: [
            "Experience in DevSecOps with a strong understanding of application security and compliance",
            "Knowledge of security tools such as SIEM, firewalls, and intrusion detection systems",
            "Bachelor's degree in Computer Science, Cybersecurity, or a related field",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Security certifications (e.g., CISSP, CEH) are a plus"
        ]
    },
    {
        title: "Cloud Architect",
        department: ["Development", "Architecture", "Cloud"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Design secure, scalable, and cost-effective cloud infrastructure solutions to support data-intensive applications",
            "Work with development teams to ensure smooth cloud migrations and optimizations",
            "Monitor and ensure cloud architecture aligns with cybersecurity best practices",
            "Continuously evaluate emerging cloud technologies for potential integration",
        ],
        qualifications: [
            "Extensive experience in cloud solution design, with a focus on scalability, security, and resilience",
            "Proficiency in cloud platforms (AWS, Azure, GCP), with relevant certifications preferred",
            "Bachelor's degree in Computer Science, Information Systems, or a related field",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Experience in implementing zero-trust security models in cloud environments is an advantage"
        ]
    },
    {
        title: "Investment Consultant",
        department: ["Business Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Provide strategic investment advice, identifying opportunities aligned with the company’s financial goals",
            "Conduct market analysis, financial forecasting, and risk assessment for business decision-making",
            "Regularly report on market trends and recommend strategic adjustments as necessary",
        ],
        qualifications: [
            "Extensive experience in investment consulting or financial advisory",
            "Deep knowledge of financial markets, investment products, and risk management",
            "Excellent analytical and communication skills",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written",
            "Certifications in finance (e.g., CFA) are beneficial"
        ]
    },
    {
        title: "Sales Manager",
        department: ["Sales"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and execute strategic sales plans to drive revenue growth in high-impact sectors such as cybersecurity and blockchain",
            "Cultivate and maintain strong client relationships, focusing on trust and long-term collaboration",
            "Identify new market opportunities, customer needs, and monitor competitive landscape to adapt sales strategies",
            "Collaborate closely with marketing and product teams to align sales initiatives with company goals",
        ],
        qualifications: [
            "Bachelor's degree in Business Administration, Marketing, or a related field",
            "Proven track record in sales management, preferably within the tech or cybersecurity industries",
            "Exceptional communication, negotiation, and leadership skills",
            "Ability to understand complex technical products and communicate their value effectively",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written; knowledge of Portuguese is a plus",
            "Experience in international sales and familiarity with cybersecurity products are highly advantageous",
            "Ability to travel as needed for client meetings and industry events"
        ]
    },
    {
        title: "Business Development Specialist",
        department: ["Business Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Identify and establish strategic partnerships to drive growth in new markets, focusing on industries requiring high data security",
            "Develop business cases for new partnerships and present them to executive leadership",
            "Conduct in-depth market research to identify emerging trends and competitive positioning",
            "Collaborate with cross-functional teams to enhance product-market fit and adapt offerings to client needs",
        ],
        qualifications: [
            "Bachelor's degree in Business, Marketing, or a related field",
            "Proven experience in business development or strategic partnerships within tech or cybersecurity sectors",
            "Exceptional analytical skills with the ability to leverage data for strategic decision-making",
            "Strong understanding of cybersecurity, blockchain, or digital identity management is a plus",
        ],
        additionalInformation: [
            "Proficiency in English, both spoken and written; Portuguese language skills are an advantage",
            "Familiarity with international markets and experience in B2B tech partnerships",
            "Excellent presentation skills and experience with CRM software"
        ]
    },
    {
        title: "Curricular Internship - Developer",
        department: ["Development"],
        type: "On-site",
        time: "Part-Time",
        location: "Lisbon",
        responsibilities: [
            "Assist in the development, testing, and maintenance of software projects under the guidance of senior team members",
            "Conduct research on emerging technologies in blockchain and cybersecurity",
            "Support the development and testing of smart contracts and decentralized applications",
            "Participate in team meetings and contribute ideas for innovation and process improvements",
            "Prepare documentation and reports to help track project progress and findings"
        ],
        qualifications: [
            "Currently enrolled in a Bachelor’s or Master’s program in Computer Science, Engineering, Cybersecurity, or a related field",
            "Basic understanding of programming languages such as Python, Java, or JavaScript",
            "Familiarity with blockchain concepts and/or cybersecurity practices is a plus",
            "Eagerness to learn and adapt in a fast-paced environment"
        ],
        additionalInformation: [
            "This is an unpaid, curricular internship opportunity intended to provide academic credits",
            "Proficiency in English is required; knowledge of Portuguese is an advantage",
            "The internship offers hands-on experience in a leading tech company and the opportunity to network with industry professionals"
        ]
    }
];
