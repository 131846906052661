export const partners = [

    
    {
        alt: "psk",
        img: 'https://media.notcybersec.com/website/Company/partners/psk.png',
        url: ""
    },
    {
        alt: "Microsoft Founders Hub",
        img: 'https://media.notcybersec.com/website/Company/partners/microsoft.png',
        url: 'https://www.microsoft.com/en-us/startups'
    },
    {
        alt: "INCM",
        img: 'https://media.notcybersec.com/website/Company/partners/INCM_versao branco.png',
        url: 'https://incm.pt/'
    },
    {
        alt: "Powered by AWS Cloud Computing",
        img: 'https://d0.awsstatic.com/logos/powered-by-aws-white.png',
        url: 'https://aws.amazon.com/what-is-cloud-computing'
    },
    {
        alt: "Invest2030",
        img: 'https://invest2030.pt/assets/logo_branco.png',
        url: 'https://invest2030.pt'
    },
    {
        alt: "Grupo Investe",
        img: 'https://media.notcybersec.com/website/Company/partners/Logo-Horizontal.png',
        url: 'https://grupoinveste.pt'
    },
    {
        alt: "BlendByte",
        img: 'https://media.notcybersec.com/website/Company/partners/blendbyte.svg',
        url: "https://blendbyte.pt/"
    },
    {
        alt: "Privado ID",
        img: 'https://media.notcybersec.com/website/Company/partners/privadoID.png',
        url: "https://www.privado.id/"
    },
    {
        alt: "lps",
        img: 'https://media.notcybersec.com/website/Company/partners/lps.png',
        url: ""
    },
]