import { PerspectiveCamera } from "three";
import { OrbitControls } from "../controls/OrbitControls";

class Orbit extends OrbitControls {
  constructor(camera: PerspectiveCamera, canvas: HTMLCanvasElement) {
    super(camera, canvas ? null : undefined);
  }

  tick = () => this.update();
}

export { Orbit };
