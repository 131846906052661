export const newsletters = [
    {
        image: "https://media.notcybersec.com/Email/assets/website/1.png",
        title: "ROOTKey's 2nd Anniversary🎉",
        url: "https://www.linkedin.com/pulse/rootkeys-2nd-anniversary-rootkey-1lkpf/?trackingId=AOCwShzZSLSWGKQblX4fMQ%3D%3D",
        empresaMedia: {
            data: "11/04/2024",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/2.png",
        title: "Zero-Knowledge Proofs with Blockchain",
        url: "https://www.linkedin.com/pulse/zero-knowledge-proofs-blockchain-rootkey-4pcaf/?trackingId=ocWJUkKnQRmrOuo4OpGP0Q%3D%3D",
        empresaMedia: {
            data: "8/03/2024",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/3.png",
        title: "🎉 ROOTKey Joins Casa da Moeda Innovation Network! 🚀",
        url: "https://www.linkedin.com/pulse/rootkey-joins-casa-da-moeda-innovation-network-rootkey-icmkf/?trackingId=3vF60RQmS%2B2jlAaQEQDE8g%3D%3D",
        empresaMedia: {
            data: "22/01/2024",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/4.png",
        title: "ROOTKey's Eventful Path",
        url: "https://www.linkedin.com/pulse/rootkeys-eventful-path-rootkey/?trackingId=VbdE45iLQI6ly%2F%2BVuyJbaw%3D%3D",
        empresaMedia: {
            data: "8/9/2023",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/5.png",
        title: "🛡️ News and thoughts of the week in Cybersecurity",
        url: "https://www.linkedin.com/pulse/news-thoughts-week-cybersecurity-rootkey/?trackingId=RsUEhn%2BZQMG%2BtcZNg3Jc5A%3D%3D",
        empresaMedia: {
            data: "16/6/2023",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/6.png",
        title: "📢 Exciting news! 🌟",
        url: "https://www.linkedin.com/pulse/exciting-news-rootkey/?trackingId=J3gLgumOTH6zCzvDwUZ8xw%3D%3D",
        empresaMedia: {
            data: "9/6/2023",
            nome: "ROOTKey",
        }
    },
    {
        image: "https://media.notcybersec.com/Email/assets/website/7.png",
        title: "Securing Data Validity and Authenticity!",
        url: "https://www.linkedin.com/pulse/securing-data-validity-authenticity-rootkey/",
        empresaMedia: {
            data: "2/6/2023",
            nome: "ROOTKey",
        }
    }
]