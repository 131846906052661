import React from 'react';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cn } from 'utils/cn';

interface NewsItem {
    title: string;
    url: string;
    image: string;
    empresaMedia: {
        data: string; // Data no formato dd/mm/aaaa
        nome: string;
    };
}

interface NewsCarouselProps extends Settings {
    newsData: NewsItem[];
    className?: string;
}

const NewsCarousel: React.FC<NewsCarouselProps> = ({
    newsData = [],
    className = "",
    autoplay = true,
    autoplaySpeed = 3000,
    dots = true,
    infinite = true,
    ...settings
}) => {
    const parseDate = (dateString: string): Date => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
    };

    const sortedNewsData = [...newsData].sort((a, b) => {
        return parseDate(b.empresaMedia.data).getTime() - parseDate(a.empresaMedia.data).getTime();
    });

    const defaultSettings: Settings = {
        dots,
        infinite,
        speed: 1000,
        autoplay,
        autoplaySpeed,
        slidesToShow: window.innerWidth > 720 ? 4 : 1,
        slidesToScroll: 1,
        centerPadding: window.innerWidth > 720 ? "130px" : "0px",
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, 
                },
            },
        ],
        ...settings,
    };

    return (
        <div className={cn("w-full overflow-hidden", className)}>
            <Slider {...defaultSettings}>
                {sortedNewsData.map((news, index) => (
                    <div key={index} className='p-3 transition-transform duration-300 hover:scale-105'>
                        <div className='relative overflow-hidden '>
                            <a
                                href={news.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="overflow-hidden bg-transparent rounded-lg "
                            >
                                <img
                                    src={news.image}
                                    alt={news.title}
                                    className="aspect-video w-full h-full object-cover z-10 rounded-lg bg-gray-400"
                                />
                                <div className="absolute bottom-0 w-full bg-black bg-opacity-70 text-white px-2 pt-3 pb-2 rounded-b-lg h-fit max-h-[50%] overflow-hidden flex flex-col gap-2 items-start justify-end">
                                    <p className="text-balance text-[14px] sm:text-[15px] md:text-[16px] lg:text-[15px] font-semibold mt-0 mb-auto w-full h-16 truncate text-clip">
                                        {news.title}
                                    </p>
                                    <div className="flex w-full justify-between h-4">
                                        <p className="text-gray-400 text-[12px]">{news.empresaMedia.nome}</p>
                                        <p className="text-gray-400 text-[12px]">{news.empresaMedia.data}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default NewsCarousel;
