export const news = [
    {
        image: "https://www.portugalglobal.pt/media/g0iigx25/blockchain.jpg?quality=80&rnd=133746840951830000",
        title: "ROOTKey Conquista Prémio Ignition pelo Vodafone Power Lab ",
        url: "https://www.portugalglobal.pt/noticias/rootkey-conquista-premio-ignition-pelo-vodafone-power-lab/?origin=serp_auto",
        empresaMedia: {
            data: "29/10/2024",
            nome: "Portugal Global",
        }
    },
    {
        image: "https://thenextbigidea.pt/wp-content/uploads/2024/10/SO_08016-scaled-e1728658754921.jpg",
        title: "Portuguesa ROOTKey prepara expansão internacional com EUA, Dubai e Japão na mira",
        url: "https://thenextbigidea.pt/portuguesa-rootkey-prepara-expansao-internacional-com-eua-dubai-e-japao-na-mira/",
        empresaMedia: {
            data: "11/10/2024",
            nome: "The Next Big Idea",
        }
    },
    {
        image: "https://www.estg.ipp.pt/noticias/estudantes-da-estg-vencem-concurso-da-fundacao-da-juventude/image",
        title: "Estudantes da ESTG vencem Concurso da Fundação da Juventude ",
        url: "https://www.estg.ipp.pt/noticias/estudantes-da-estg-vencem-concurso-da-fundacao-da-juventude",
        empresaMedia: {
            data: "27/06/2023",
            nome: "ESTG",
        }
    },
    {
        image: "https://images.impresa.pt/sicnot/2022-11-04-image00001.jpeg-325f9b2c/original/mw-1440",
        title: "Web Summit: o que estão a inventar os empreendedores portugueses?",
        url: "https://sicnoticias.pt/especiais/web-summit-/2022-11-04-Web-Summit-o-que-estao-a-inventar-os-empreendedores-portugueses--497e2e4b",
        empresaMedia: {
            data: "04/11/2022",
            nome: "SIC NOTÍCIAS",
        }
    }
]
