import React from 'react';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import './styles.css'
import cookie from '../../assets/cookie.png'
import { HiOutlineExternalLink } from 'react-icons/hi';
import { useContact } from 'hooks/useContact';

export default function Cookies() {
    const { t } = useTranslation();

    const {cookies, setCookieHandler} = useContact();

    if (cookies.value !== undefined) {
        return null
    }

    return (
        <div id='cookies'>
            <img src={cookie} alt="Cookie" />
            <p>{t('cookie.text')}<a href="/legal/cookies-policy" className='flex gap-1 inline-flex w-fit'>{t('cookie.legal')}<HiOutlineExternalLink /></a></p>
            <div>
                <button type='submit' onClick={() => {setCookieHandler(true)}}>{t('cookie.Accept')}</button>
                <button type='submit' onClick={() => {setCookieHandler(false)}}>{t('cookie.Decline')}</button>
            </div>
        </div>
    )
} 
